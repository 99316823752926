// --- Alphanumeric Regex --- //

const alphanumericRegex = /^[a-zA-Z0-9\-]*$/;

export function isAlphanumeric(sequence: string, exceptions?: string[]): boolean {
    return testRegexWithExceptions(alphanumericRegex, sequence, exceptions);
}

// --- Spanish Text Regex --- //

const spanishTextRegex = /^[A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ\s]*$/; // alphanumeric, special spanish characters and spaces

export function isText(sequence: string, exceptions?: string[]): boolean {
    return testRegexWithExceptions(spanishTextRegex, sequence, exceptions);
}

// --- Prisma Id Regex --- //

const prismaIdRegex = /^[0-9a-fA-F]{24}$/; // prisma @ObjectId type

export function isPrismaObjectId(sequence: string): boolean {
    return prismaIdRegex.test(sequence);
}

// --- Email Regex --- //

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export function isEmail(sequence: string): boolean {
    return emailRegex.test(sequence);
}

// --- Promotional Code Regex --- //

const promotionalCodeRegex = /^[A-Z0-9]{0,250}$/;

export function isPromotionalCode(sequence: string): boolean {
    return promotionalCodeRegex.test(sequence);
}

// --- Search Bar Regex --- //

const searchBarRegex = /^[A-Za-z0-9áéíóúÁÉÍÓÚüÜñÑ\s\-_+.@]*$/;

export function isSearchBarText(sequence: string): boolean {
    return searchBarRegex.test(sequence);
}

// --- Text Chunk Regex --- //

export function splitIntoChunks(text: string, chunkSize: number): string[] {
    const regex = new RegExp(`(.|\n|\r|){1,${chunkSize}}`, 'g');
    return text.match(regex) || [];
}

// --- Utils --- //

function testRegexWithExceptions(regex: RegExp, sequence: string, exceptions?: string[]): boolean {
    let finalSequence = sequence;

    if (typeof exceptions !== 'undefined') {
        for (const substring of exceptions) {
            finalSequence = finalSequence.replaceAll(substring, '');
        }
    }

    return regex.test(finalSequence);
}

const objectIdsRegex = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
export function isMongoId(id: string): boolean {
    return objectIdsRegex.test(id);
}

const phoneNumberRegex =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export function isPhoneNumber(number: string): boolean {
    return phoneNumberRegex.test(number);
}

const regexSpecialChars = /[.*+?^${}()|[\]\\]/g;
export function escape(input: string) {
    return input.replace(regexSpecialChars, '\\$&');
}
